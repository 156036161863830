.highlight {
    background-color: rgb(0, 217, 255); /* Highlight color */
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 15px 20px;
    color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%; /* Prend toute la largeur disponible */
    max-width: none; /* Enlève la limite de largeur */
    margin: 20px 0; /* Espacement vertical pour centrer */
}
