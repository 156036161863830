.clear-button {
    background-color: #d9534f; /* Red background */
    color: white;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    width: auto;
    font-size: 14px;
    transition: background-color 0.3s, border-color 0.3s;
    margin-left: 10px; /* Espace entre "Waiting Room" et "Clear" */
}

.clear-button:hover {
    background-color: rgba(217, 83, 79, 0.8); /* Fond rouge plus opaque au survol */
    border-color: rgba(217, 83, 79, 1);
    color: white;
}
