/* Conteneur flex pour les tableaux et les groupes */
.table-container,
.party-table-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centre les éléments horizontalement */
    gap: 20px; /* Espace entre les éléments */
    margin-top: 20px;
}


/* Style pour chaque tableau et chaque groupe */
.table-wrapper {
    flex: 1 1 45%; /* Ajuste la taille pour deux tableaux par ligne */
    min-width: 245px;
    max-width: 45%; /* Ajuste la largeur maximale à 45% pour deux tableaux par ligne */
    background: rgba(255, 255, 255, 0.1); /* Fond transparent similaire à l'input */
    border: 2px solid rgba(255, 255, 255, 0.2); /* Bordure légère */
    backdrop-filter: blur(20px); /* Ajoute un flou pour un effet similaire */
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Ombre légère pour donner de la profondeur */
    overflow-x: auto; /* Permet un défilement horizontal si nécessaire */
    display: flex;
    flex-direction: column;
    align-items: center; /* Centre les éléments horizontalement */
    color: #fff; /* Texte blanc pour correspondre au thème */
}


/* Style pour les tables */
table {
    width: 100%; /* Prend toute la largeur du conteneur */
    border-collapse: collapse;
    table-layout: auto; /* Permet aux colonnes de s'adapter à leur contenu */
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    font-size: 0.9em;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

th {
    background-color: #564caf;
    color: white;
}

tbody tr:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.1); /* Fond léger pour les lignes impaires */
}

tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.2); /* Fond plus sombre pour les lignes paires */
}

tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

/* Styles pour centrer les titres */
.title,
.subtitle,
.table-wrapper h2 {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

/* Ajuste les styles des listes dans PartyTable */
.party-card ul {
    list-style-type: none;
    padding: 0;
}

.party-card li {
    text-align: center;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
}

.party-card li:last-child {
    border-bottom: none;
}

/* Media query pour les petits écrans */
@media (max-width: 768px) {
    .table-container,
    .party-table-container {
        flex-direction: column; /* Les éléments seront empilés verticalement sur les petits écrans */
        align-items: center; /* Centre les éléments lorsqu'ils sont empilés verticalement */
    }

    .table-wrapper {
        flex: 1 1 100%; /* Prend toute la largeur sur les petits écrans */
    }

    th,
    td {
        font-size: 0.8em; /* Réduit la taille de la police pour les petits écrans */
        padding: 6px; /* Réduit l'espacement pour les petits écrans */
    }
}

.created-character-info {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background: rgba(255, 255, 255, 0.1); /* Fond semi-transparent */
    border: 2px solid rgba(255, 255, 255, 0.2); /* Bordure légère */
    backdrop-filter: blur(10px); /* Effet de flou */
    border-radius: 10px; /* Coins arrondis pour harmoniser le style */
    padding: 15px 20px; /* Ajoute du padding pour donner de l’espace */
    color: #fff; /* Texte blanc */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Ombre légère pour donner de la profondeur */
    width: 100%; /* Prend toute la largeur disponible */
    max-width: 800px; /* Limite la largeur maximale */
    margin: 20px auto; /* Centre horizontalement */
}

.icon-text-container {
    display: flex;
    align-items: center; /* Centre verticalement l'icône et le texte */
    justify-content: center; /* Centre horizontalement le contenu */
}

/* Styles spécifiques pour ajuster la largeur des nouvelles colonnes */
th.new-column,
td.new-column {
    min-width: 150px; /* Ajuste la largeur minimale selon le contenu */
}

.title-clear-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.party-button-container {
    display: flex; /* Aligner les boutons horizontalement */
    gap: 10px; /* Espacement uniforme entre les boutons */
    align-items: center; /* Centrer les boutons verticalement */
}

.party-button-container button {
    height: 40px; /* Assure une hauteur uniforme */
    display: flex; /* Pour aligner le contenu à l'intérieur */
    align-items: center; /* Centrer verticalement le contenu */
    justify-content: center; /* Centrer horizontalement le contenu */
}
